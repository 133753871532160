






import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OAddAbstract: () =>
      import("@/components/organisms/event/abstractModule/o-add-abstract.vue"),
  },
  setup() {
    return {};
  },
});
